import type {MetaFunction, LoaderFunction} from '@remix-run/node';
import {json} from '@remix-run/node';
import {Outlet} from '@remix-run/react';

export interface RootPublicLoaderData {
  coordinator: string;
  telephone: string;
  address: string;
}

export const meta: MetaFunction = function RootPublicMeta() {
  return [{title: 'Principal - Cindedi'}];
};

export const loader: LoaderFunction = async function RootPublicLoader({context: {use}}) {
  const Metadata = use('Metadata');

  const [coordinator, telephone, address] = await Promise.all([
    Metadata.findOrFail({where: {key: 'coordinator'}}),
    Metadata.findOrFail({where: {key: 'telephone'}}),
    Metadata.findOrFail({where: {key: 'address'}}),
  ]);

  return json({
    coordinator: coordinator.value,
    telephone: telephone.value,
    address: address.value,
  });
};

export default function RootPublicView() {
  return <Outlet />;
}
